<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="min-height: 100vh; background: #e6e6e6"
  >
    <v-row class="my-3">
      <v-col class="txt28-gray50-bold"> Struktur Organisasi </v-col>
    </v-row>
    <v-card class="px-4 py-3 elevation-0">
      <div class="d-flex justify-center">
        <span
          style="cursor: pointer; color: #2b7dec"
          @click="toDetail(bpp, 'bpp')"
        >
          Lihat Struktur Lengkapnya
          <v-icon color="#2B7DEC"> mdi-chevron-right </v-icon>
        </span>
      </div>
    </v-card>
    <v-row class="my-3">
      <v-col class="txt28-gray50-bold"> Badan Pengurus Daerah </v-col>
    </v-row>
    <v-expansion-panels v-model="panels" multiple flat>
      <v-expansion-panel
        v-for="(v, i) in bpd"
        :key="'bpd-' + i"
        class="mb-3 elevation-0"
      >
        <v-expansion-panel-header class="txt16-gray50-bold">
          {{ v.tab_main_title }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="d-flex justify-center">
            <span
              style="cursor: pointer; color: #2b7dec"
              @click="toDetail(v, 'bpd', i)"
            >
              Lihat Struktur Lengkapnya
              <v-icon color="#2B7DEC"> mdi-chevron-right </v-icon>
            </span>
          </div>
          <v-row v-if="v.bpc">
            <v-col class="txt16-gray50-bold"> Badan Pengurus Cabang </v-col>
          </v-row>
          <template v-if="v.bpc">
            <v-row v-for="(x, y) in v.bpc" :key="'bpc' + y">
              <v-col class="d-flex justify-space-between">
                <span class="txt16-gray50">
                  {{ x.tab_main_title }}
                </span>
                <span
                  style="cursor: pointer; color: #2b7dec"
                  @click="toDetail(v, 'bpc', y)"
                >
                  Lihat Struktur Lengkapnya
                  <v-icon color="#2B7DEC"> mdi-chevron-right </v-icon>
                </span>
              </v-col>
            </v-row>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.post["x-api-key"] = localStorage.getItem("token");

export default {
  components: {},

  data: () => ({
    panels: [],
    bpp: null,
    bpd: [],
  }),

  computed: {},

  watch: {},

  created() {
    this.getBpp();
    this.initialize();
  },

  methods: {
    toDetail(p, p2, p3) {
      // console.log(p)
      // console.log(p2)
      if (p2 === "bpp") {
        this.$router.push({
          name: "StrukturBpp",
          params: { id: p.tab_main_hash },
        });
      }
      if (p2 === "bpd") {
        this.$router.push({
          name: "StrukturBpd",
          params: { id: p.tab_main_hash, index: p3 },
        });
      }
      if (p2 === "bpc") {
        this.$router.push({
          name: "StrukturBpc",
          params: { id: p.tab_main_hash, index: p3 },
        });
      }
    },

    initialize() {
      axios
        .post("/v1/admin/structure-org/get-bpd", {})
        .then((res) => {
          if (res.data.status === 200) {
            this.bpd = res.data.data.bpd.list;
            const bpdLength = this.bpd.length;
            for (let i = 0; i < bpdLength; i++) {
              this.getBpc(this.bpd[i].tab_main_hash, i);
            }
          }
        })
        .catch((e) => {
          if (typeof e.response.data.error === "object") {
            this.$toast.error(Object.values(e.response.data.error)[0][0]);
          } else {
            this.$toast.error(e.response.data.error);
          }
        });
    },

    getBpp() {
      axios.post("/v1/admin/structure-org/get-bpp", {}).then((res) => {
        if (res.data.status === 200) {
          this.bpp = res.data.data;
        }
      });
    },

    getBpc(p, p2) {
      const reqBody = {
        bpd_hash: p,
      };
      axios.post("/v1/admin/structure-org/get-bpc", reqBody).then((res) => {
        if (res.data.status === 200) {
          // console.log(res.data.data.bpc.list)
          this.bpd[p2].bpc = res.data.data.bpc.list;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.txt16-gray50 {
  @extend .p-1;
  color: $gray-50;
}

.txt16-gray50-bold {
  @extend .p-1;
  color: $gray-50;
  font-weight: bold;
}

.txt28-gray50-bold {
  @extend .h-4;
  color: $gray-50;
  font-weight: bold;
}
</style>
